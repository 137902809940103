import axios from 'axios';

const ApiService = axios.create({
    // baseURL: 'http://localhost:5000',
    // baseURL: 'https://bssa-db.vercel.app',
    baseURL: 'https://bssa-database.vercel.app',// this is the currently api
    // timeout: 10000, // optional timeout
    headers: {
        'Content-Type': 'application/json', // Set default headers if needed
    },
});

export default ApiService;
