import React, { Suspense, lazy } from "react";

import { SimpleGrid } from "@chakra-ui/react";
const GalleryCarousal = lazy(() => import("./GalleryCarousal.jsx"));
const VideoCarousal = lazy(() => import("./VideoCarousal.jsx"));

const GallaryAndVideo = () => {
  return (
    <div id="gallery-home">
      <div className="container">
        <SimpleGrid columns={[1, 2, 2, 2]}>
          {/* <GalleryThree /> */}
          {/* <GallerySection /> */}
          <Suspense fallback={<div>Loading gallery...</div>}>
            <GalleryCarousal />
          </Suspense>
          <Suspense fallback={<div>Loading videos...</div>}>
            <VideoCarousal />
          </Suspense>
        </SimpleGrid>
      </div>
    </div>
  );
};

export default GallaryAndVideo;
