import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import TopHeadlineCard from "./TopHeadlineCard";
import Styled from "../pages/ESportLinks/ESportLinks.module.css";

const cards = [
  {
    id: 14,
    title: "द्वितीय बिहार स्टेट ओपन ई स्पोर्ट्स चैम्पियनशिप 2025",
    text: "22 जनवरी 2025 को पटलिपुत्र खेल परिसर में द्वितीय बिहार स्टेट ओपन ई स्पोर्ट्स चैम्पियनशिप आयोजित की जा रही है। यह बिहार का पहली आधिकारिक ई स्पोर्ट्स प्रतियोगिता है। इस वर्ष 6000 से अधिक खिलाड़ियों ने रजिस्ट्रेशन किया, जिनमें से 115 खिलाड़ी फाइनल में पहुंचे हैं। प्रतियोगिता में 6 खेल शामिल हैं: बीजीएएमआई, ई चेस, ई फुटबॉल (मोबाइल), स्ट्रीट फाइटर, ईए स्पोर्ट्स एफसी 24, और रियल क्रिकेट 24। विजेताओं को नकद पुरस्कार और ई सर्टिफिकेट दिया जाएगा ।",
    image: "img/esports.jpg",
    url: "#",
  },
  {
    id: 13,
    title: "68वीं राष्ट्रीय स्कूल खेल साइकिलिंग प्रतियोगिता 2025",
    text: "बिहार में 68वीं राष्ट्रीय स्कूल खेल साइकिलिंग प्रतियोगिता (अंडर 14, 17, 19 लड़के और लड़कियां) का आयोजन 22 से 24 जनवरी 2025 तक पाटलिपुत्र स्पोर्ट्स कॉम्प्लेक्स, पटना में होगा। इसमें देशभर के युवा खिलाड़ी भाग लेंगे। प्रतियोगिता में भाग लेने के लिए रिपोर्टिंग की अंतिम तारीख 21 जनवरी 2025 है, और ऑनलाइन प्रवेश की अंतिम तिथि 16 जनवरी 2025 तक है। यह आयोजन खिलाड़ियों को अपनी प्रतिभा दिखाने का राष्ट्रीय मंच प्रदान करेगा।",
    image: "img/cyclingCompetition.jpg",
    url: "#",
  },
  {
    id: 12,
    title: "68वीं नेशनल स्कूल गेम्स रग्बी 2024-25",
    text: "68वीं नेशनल स्कूल गेम्स रग्बी 7s बालक और बालिकाओं की प्रतियोगिता पाटलिपुत्र खेल परिसर, कंकड़बाग में आयोजित किया जा रही  है। अंडर-19 (लड़के और लड़कियों) की प्रतियोगिता 24 से 25 दिसंबर, अंडर-17 (लड़के और लड़कियों) की प्रतियोगिता 28 से 29 दिसंबर, और अंडर-14 (लड़के और लड़कियों) की प्रतियोगिता 1 से 2 जनवरी तक आयोजित होगी। यह आयोजन विभिन्न आयु वर्ग के खिलाड़ियों को अपनी प्रतिभा प्रदर्शित करने का अवसर प्रदान करेगा।",
    image: "img/ruggby_icon.jpg",
    url: "#",
  },
  {
    id: 11,
    title: "मशाल ",
    text: "बिहार में पहली बार होने जा रही है देश की सबसे बड़ी खेल प्रतिभा खोज प्रतियोगिता - मशाल । इसमें एथेलेटिक्स ,साइकिलिंग, फुटबॉल ,कबड्डी और  वॉलीबॉल खेलों की प्रतियोगिता होगी । विद्यालय, संकुल, प्रखंड, जिला और राज्य स्तर पर यह प्रतियोगिता आयोजित की जायेगी। इसमें राज्य स्तर पर चुनें गए छात्र को प्रेरणा छात्रवृति योजना के अंतर्गत 3 लाख रुपए तक की छात्रवृति दी जायेगी। विजेता छात्रों को मेडल, नक़द राशि के साथ- साथ सार्टिफिकेट दी जायेगी।",
    image: "img/Mashal icon.jpg",
    // url: "/Bihar-ACT",
    url: "#",
  },
  {
    id: 10,
    title: "फेंसिंग (तलवारबाजी)",
    text: "बिहार में पहली बार खेल विभाग और बिहार राज्य खेल प्राधिकरण द्वारा आयोजित की गई है 32 वीं नेशनल जूनियर फेंसिंग चैम्पियनशिप 2024-25 । यह 2 से 5 दिसंबर तक पाटलिपुत्र खेल परिसर के इंडोर हॉल में आयोजित है। इसमे 29 राज्य और केन्द्र शासित क्षेत्र के 800 से ज्यादा अंडर 20 के खिलाड़ी शामिल हैं। ",
    image: "img/Fencing icon.jpg",
    // url: "/Bihar-ACT",
    url: "#",
  },

  {
    id: 9,
    title: "बिहार महिला एशियाई चैंपियंस ट्रॉफी 2024",
    text: 'बिहार वर्ष 2024 के लिए "बिहार विमेंस एशियन चैंपियंस ट्रॉफी 2024" की मेज़बानी 11 - 20 नवंबर तक कर रहा है । इस टूर्नामेंट का नाम "हॉकी का पर्व, बिहार का गर्व" है, जिसमे भारत, मलेशिया, चीन, कोरिया, जापान और इंडोनेशिया की छह एशियाई टीमें प्रतिस्पर्धा कर रही हैं।',
    image: "img/ACTHome.jpeg",
    // url: "/Bihar-ACT",
    url: "#",
  },
  {
    id: 8,
    title: "प्रथम बिहार वॉलीबॉल लीग 2024 ",
    text: "बिहार राज्य खेल प्राधिकरण द्वारा प्रथम बिहार वॉलीबॉल लीग का आयोजन 8 से 15 सितम्बर, 2024 तक पाटलिपुत्र खेल परिसर, इनडोर स्टेडियम,पटना  में होने जा रहा है जिसमें 6 टीमें (पाटलिपुत्र एसर्स, मिथिला स्पाइकर्स, विक्रमशिला ब्लॉकर्स, मगध सेटर्स, तक्षशिला सर्वर्स और नालंदा डिफेंडर्स ) भाग ले रहीं हैं।",
    image: "img/VallyballFlogo.jpeg",
    url: "/Bihar-Volleyball-League",
  },
  {
    id: 7,
    title: "राजगीर खेल विश्वविद्यालय एवं खेल अकादमी का उद्घाटन ",
    text: "29 अगस्त 2024 को माननीय मुख्यमंत्री श्री नीतीश कुमार द्वारा राजगीर खेल विश्वविद्यालय और खेल अकादमी का उद्घाटन किया गया। यह बिहार का पहला  खेल विश्वविद्यालय है। इस परिसर में हॉकी टर्फ, स्विमिंग पूल, फुटबॉल ग्राउंड, क्रिकेट स्टेडियम, और इनडोर स्टेडियम जैसी सुविधाएं बनाई गई हैं। उद्घाटन के अवसर पर एक प्रदर्शन मैच का भी आयोजन हुआ, जिसमें भारतीय महिला हॉकी टीम ने भाग लिया।",
    image: "img/Webpage Logo.jpg",
    url: "/rajgir-stadium-inauguration",
  },
  {
    id: 6,
    title: "पेरिस ओलंपिक खेल 2024 में भारत ",
    text: "पेरिस ओलंपिक खेल 2024 का आयोजन 26 जुलाई 2024 से 11 अगस्त 2024 तक किया जा रहा है। पेरिस ओलंपिक 2024 में  भारत की ओर से 16 खेल विधाओं में 117 एथलीट हिस्सा लेंगे, जिनमें 70 पुरुष और 47 महिलाएँ शामिल हैं। वे 95 पदकों के लिए 69 स्पर्धाओं में भाग लेंगे। पेरिस ओलिंपिक 2024 में भारत द्वारा पदक प्राप्ति की वर्तमान स्थिति एवं खेल कार्यक्रम और परिणाम की विस्तृत जानकारी के लिए क्लिक करें।",
    image: "img/parisOlampic.jpeg",
    url: "/olympic-Schedule-Table",
  },
  {
    id: 1,
    title: "प्रथम बिहार वुमेन कबड्डी लीग 2024",
    text: "बिहार राज्य खेल प्राधिकरण द्वारा प्रथम बिहार वूमेन कबड्डी लीग का आयोजन 10 जून से 16 जून, 2024 तक पाटलिपुत्र खेल परिसर, इंडोर स्टेडियम, पटना में होने जा रहा है जिसमें 06 टीमें ( सीतामढ़ी सेंटीनेल्स  ,मगध वरियर्स ,सारण स्ट्राइकर्स , पटना पेलिकन , नालंदा निंजास ,सीवान टाइटेंस )  हिस्सा ले रहीं हैं।",
    image: "img/kabbadilig.jpg",
    url: "/bihar-Women-Kabaddi-League",
  },
  // {
  //   id: 2,
  //   title: "67 वां नेशनल स्कूल गेम्स 2023",
  //   text: "स्कूल गेम्स फेडरेशन ऑफ इंडिया के द्वारा 67वां नेशनल स्कूल गेम्स 2023 में 05 खेल विधाओं  एथलेटिक्स, क्रिकेट, सेपक टाकरा, भारोत्तोलन और फुटबॉल  का आयोजन बिहार में किया जा रहा है।",
  //   image: "img/sixty_seventh_sgfi.jpg",
  //   url: "/national-school-games",
  // },
  // {
  //   id: 4,
  //   title: " प्रो कबड्डी लीग 10वां सीजन 2023-24",
  //   text: "प्रो कबड्डी लीग के 10वें सीजन का आयोजन 2 दिसंबर 2023 से 21 फरवरी 2024 तक किया जा रहा है। इस वर्ष पटना पाइरेट्स टीम को बिहार सरकार एवं बिहार राज्य खेल प्राधिकरण प्रायोजित कर रही है।",
  //   image: "img/ProKabaddi.jpg",
  //   url: "/prokabaddi",
  // },
  // {
  //   id: 3,
  //   title: "चेस चैंपियनशिप 2024",
  //   text: " बिहार राज्य खेल प्राधिकरण के द्वारा चेस चैंपियनशिप का आयोजन दिनांक: 05 फरवरी 2024 से 10 फरवरी 2024 तक  किया जा रहा है।  ",
  //   image: "img/Chess.jpg",
  //   url: "#",
  // },
  // {
  //   id: 5,
  //   title: "ई स्पोर्ट्स प्रतियोगिता 2023",
  //   text: "बिहार राज्य खेल प्राधिकरण के द्वारा बिहार में पहली बार ई- स्पोर्ट्स प्रतियोगिता का आयोजन दिनांक 16 दिसंबर से 22 दिसंबर  2023 तक किया जा रहा है । इस प्रतियोगिता का ग्रैंड फिनाले 28 दिसंबर 2023 को पाटलिपुत्र खेल परिसर में ही खेला जाएगा। ई-स्पोर्ट्स प्रतियोगिता के अंतर्गत खिलाड़ी विभिन्न 4 ऑनलाइन खेलों के लिए अपना पंजीकरण कर सकते हैं।",
  //   image: "img/ESport.jpg",
  //   url: "/esportlinks",
  // },

  // {
  //   id: 2,
  //   title: "नेशनल गेम्स - 2023",
  //   text: "नेशनल गेम्स का आयोजन दिनांक 25 अक्टूबर से 09 नवंबर 2023 तक किया जा रहा है। भारत का नाम विश्व पटल पर गौरवान्वित करने वाले कई दिग्गज खिलाड़ी इस प्रतियोगिता में सहभागिता कर चुके हैं।प्रथम नेशनल गेम्स का आयोजन अविभाजित भारत के लाहौर शहर में वर्ष 1924 में किया गया था। साल 2022 में जहां नेशनल गेम्स में 36 विभिन्न खेल विषयों को शामिल किया गया था वहीं इस बार खेल विधाओं संख्या बढ़कर 43 हो गई है।",
  //   image: "img/national-games.jpeg",
  //   url: "/nationalgames",
  // },
];

const NewTopHeadline = () => {
  return (
    <div
      className={`container`}
      style={{
        borderRadius: "8px",
        marginTop: "15px",
        marginBottom: "5px",
      }}
    >
      <Tabs isManual variant="enclosed">
        <TabList>
          {/* <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
          द्वितीय मल्लयुध्द प्रतियोगिता 
          </Tab> */}
          <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            द्वितीय बिहार स्टेट ओपन ई स्पोर्ट्स चैम्पियनशिप 2025
          </Tab>
          <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            68वीं राष्ट्रीय स्कूल खेल साइकिलिंग प्रतियोगिता 2025
          </Tab>
          <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            68वीं नेशनल स्कूल गेम्स रग्बी 2024-25
          </Tab>
          <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            मशाल
          </Tab>
          <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            फेंसिंग (तलवारबाजी)
          </Tab>
          <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            बिहार महिला एशियाई चैंपियंस ट्रॉफी 2024
          </Tab>
          <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            प्रथम बिहार वॉलीबॉल लीग 2024
          </Tab>
          {/* <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            राजगीर खेल विश्वविद्यालय एवं खेल अकादमी का उद्घाटन
          </Tab> */}
          <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            पेरिस ओलंपिक खेल 2024 में भारत
          </Tab>
          {/* <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            प्रथम बिहार वुमेन कबड्डी लीग 2024
          </Tab> */}
          {/* <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            67 वां नेशनल स्कूल गेम्स 2023
          </Tab>
          <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            प्रो कबड्डी लीग 10वां सीजन
          </Tab> */}
          {/* <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            चेस चैंपियनशिप- 2024
          </Tab> */}
          {/* <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            ई स्पोर्ट्स प्रतियोगिता 2023
          </Tab> */}
          {/* <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            नेशनल गेम्स - 2023
          </Tab> */}
        </TabList>
        <TabPanels
          style={{
            borderLeft: "1px solid #cfd9de",
            borderRight: "1px solid #cfd9de",
            borderBottom: "1px solid #cfd9de",
            borderRadius: "0px 0px 5px 5px",
          }}
        >
          {/* initially mounted */}

          {cards.map((card, index) => (
            <TabPanel key={index}>
              <TopHeadlineCard card={card} />
            </TabPanel>
          ))}

          {/* <TabPanel>
            <p>one!</p>
          </TabPanel>
          initially not mounted
          <TabPanel>
            <p>two!</p>
          </TabPanel> */}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default NewTopHeadline;
