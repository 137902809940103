import React, { useRef, useState } from "react";

export const WhatNew = () => {
  const [isPaused, setPaused] = useState(false);
  // const handleMouseOver = () => {
  //   setPaused(true);
  // };
  // const handleMouseOut = () => {
  //   setPaused(false);
  // };

  const marqueeRef = useRef(null);

  const handleMouseOver = () => {
    marqueeRef.current.stop();
  };

  const handleMouseOut = () => {
    marqueeRef.current.start();
  };

  return (
    <div>
      <div className="whatsNew-section">
        <div className="container">
          <div className="whatsNew-content">
            <h4
              className="whatsnew-heading"
              style={{ backgroundColor: "#016938" }}
            >
              <a
                href=""
                className="whatsnew-link"
                title="Click here to View What's New Details "
              >
                {/* हाइलाइट्स */}
                नवीनतम सूचनाएं और अपडेट
              </a>
            </h4>
            <div className="whatsNew-list-inner">
              <marquee
                // onmouseover="this.stop();" onmouseout="this.start();"
                ref={marqueeRef}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                <ul className="whatsNew-list" style={{ alignItems: "center" }}>
                  {/* <!--
               <li>
                     <a href="Download/SportsERPPublicServicesUMUpdated.pdf" target="_blank">User Manual Public Services</a>
                  </li>--> */}
                  <li>
                    <a
                      href="https://gad-ospr.bihar.gov.in/(S(gwhdt5c21xlqx4ih40dj2jck))/Default.aspx"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      राज्य के उत्कृष्ट खिलाड़ियों से नियुक्ति हेतु ऑनलाईन आवेदन (वर्ष 2024 के लिए) (अंतिम तिथि: 5 दिसंबर 2024, रात्रि 12 बजे)
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://club.biharsports.org"
                    // target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      पंचायत खेल क्लब के पंजीकरण की विस्तारित तिथि 28 फ़रवरी 2025, रात्रि 12.00 बजे
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://coach.biharsports.org"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      प्रशिक्षकों की नियुक्ति (अनुबंध के आधार पर) के लिए आवेदन
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="img/notices/Sports Scholarship - Saksham Selected List.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      सक्षम खेल छात्रवृत्ति हेतु चयनित खिलाड़ियों की सूची
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="img/notices/Sports Scholarship - Utkarsh Selected List.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      उत्कर्ष खेल छात्रवृत्ति हेतु चयनित खिलाड़ियों की सूची
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="img/notices/Ashiyan-ragby-championship-player-list.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      एशियन रग्बी अंडर 18 चैंपियनशिप 2024 के लिए भारतीय खिलाडियों की फाइनल सूची
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="https://club.biharsports.org"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      बिहार पंचायत खेल क्लब पंजीकरण
                      अंतिम तिथि: 14 अक्टूबर 2024, रात्रि 12 बजे
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="img/notices/Bihar_Divisional_Esports_Championship.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      बिहार डिवीजनल ई-स्पोर्ट्स चैम्पियनशिप 2024

                      राज्य स्तरीय फाइनल के लिए चुने गए खिलाड़ी/टीमों की सूची
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>
                  <li>
                    <a
                      // href="img/notices/KSS_2024_Final_Awardee_List.pdf"
                      href="img/notices/KSS-2024_List of Accepted Players.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      खेल सम्मान - 2024:- स्वीकृत पुरस्कृतों की सूची ( प्रकाशित:- तिथि:- 04 सितंबर 2024, समय:- 12:07 अपराह्न )
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="img/tenders/Eklavya trials- Edited - Google Sheets.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      एकलव्य आवासीय प्रशिक्षण केंद्र के लिए चयन ट्रायल हेतु खेल
                      विधा, आयोजन स्थल एवं तिथि की विवरणी
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>
                  <li>
                    <a href="img/tenders/Aklabya2682.PDF" target="_blank">
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      एकलव्य आवासीय प्रशिक्षण केन्द्र के चयन हेतु चयन ट्रायल में
                      सभी जिलों के सभी सरकारी एवं गैर सरकारी स्कूलों के
                      खिलाड़ियों की भागीदारी संबधित
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://scholarship.biharsports.org/"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      बिहार राज्य खेल छात्रवृत्ति (एथलीटों को) के लिए आवेदन करने
                      हेतु पोर्टल https://scholarship.biharsports.org/ पर क्लिक
                      करें।<span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  {/* <li>
                    <a href="https://kss.biharsports.org/" target="_blank">
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      खेल सम्मान समारोह-2024 में पंजीयन करने के लिए वेब पेज
                      https://kss.biharsports.org/ पर क्लिक करें
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}
                  <li>
                    <a href="img/tenders/NewDateforPrebidl.PDF" target="_blank">
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      बिहार राज्य खेल प्राधिकरण के लिए खेल किट निविदाओं के लिए
                      नई तिथि
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="img/tenders/MESSRPFFULLDETAILSPDFFILE.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      पाटलिपुत्र खेल परिसर छात्रावास, पटना के लिए पांच वर्षों की
                      अवधि के लिए कैटरिंग एजेंसी के चयन हेतु निविदा
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="img/notices/KheloindiaStateAwashiye yojna.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      खेलो इंडिया स्टेट सेंटर ऑफ एक्सीलेंस, पटना में आवासीय
                      योजना के अंतर्गत नामांकन प्रस्ताव
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="img/notices/BWKL_Revised_Schedule.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      बिहार वुमेन कबड्डी लीग 2024 की संशोधित अनुसूची
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="img/notices/BSSA_NEWSLETTER_JUNE.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      BSSA NEWSLETTER JUNE
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.youtube.com/watch?v=QLs67Mnuayg"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      बिहार महिला कबड्डी लीग मैच का सीधा प्रसारण पाटलिपुत्र खेल
                      परिसर, पटना से<span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  <li>
                    <a href="img/notices/कुश्ती_merged.pdf" target="_blank">
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      कुश्ती, भारोत्तोलन एवं एथलेटिक्स के लिए चयनित खिलाड़ियों
                      की सूची<span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>
                  <li>
                    <a href="img/notices/KISCE_Athletics.pdf" target="_blank">
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      खेलो इंडिया स्टेट सेंटर ऑफ एक्सिलेंस ट्रायल ( एथलेटिक्स )
                      परिणाम
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="img/notices/KISCE_Wretling ist.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      खेलो इंडिया स्टेट सेंटर ऑफ एक्सिलेंस ट्रायल ( कुश्ती )
                      परिणाम
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  {/* <li>
                    <a
                      href="img/Result/Letter No. 1600 Dt. 03.05.2024.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      सीसीटीवी कैमरा इंस्टालेशन हेतु कोटेशन आमंत्रण
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="img/Result/WL Shorlisted Athletes.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      खेलो इंडिया स्टेट सेंटर ऑफ एक्सिलेंस ट्रायल ( भारोत्तोलन )
                      परिणाम
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}
                  {/* <li>
                    <a href="https://play.google.com/store/apps/details?id=com.bbfs.parent" target="_blank">
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      बाईचुंग भूटिया स्कूल ऑफ फुटबॉल के सिलेक्शन ट्रायल प्रक्रिया में पंजीकरण के लिए इस Link पर क्लिक करें।
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}
                  {/* <li>
                    <a href="https://linktr.ee/univsports" target="_blank">
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      खेलो इंडिया स्टेट सेंटर ऑफ एक्सिलेंस के एथलेटिक्स चयन ट्रायल रजिस्ट्रेशन के लिए UNIV ऐप इंस्टाल करें।
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}
                  {/* <li>
                    <a href="img/tenders/LETTER NO- 986.pdf" target="_blank">
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      57वीं नेशनल स्कूल एथलेटिक्स, अंडर-17 (बॉयज एंड गर्ल्स)
                      चैंपियनशिप 2023-24 का शेड्यूल
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}

                  {/* <li>
                    <a href="/careerandopportunity" target="_blank">
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      रग्बी कोच की नियुक्ति के लिए आवेदन की अंतिम तिथि - 10
                      मार्च 2024
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}

                  {/* <li>
                    <a href="/careerandopportunity" target="_blank">
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      टेबल टेनिस कोच की नियुक्ति के लिए आवेदन की अंतिम तिथि - 10
                      मार्च 2024
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}

                  {/* <li>
                    <a href="/careerandopportunity" target="_blank">
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      कुश्ती कोच की नियुक्ति के लिए आवेदन की अंतिम तिथि - 10
                      मार्च 2024
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}
                  {/*  requirement */}

                  <li>
                    <a
                      href="img/tenders/Corrigendum_Travel Agency.docx"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      शुद्धिपत्र - बिहार राज्य खेल प्राधिकरण हेतु 03 वर्ष के
                      समयावधि के लिए ट्रांसपोर्टेशन एजेंसी का मनोनयन|
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="img/tenders/RFP_FOR_Travel_Agency_for_BSSA_PDF_full_details.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      बिहार राज्य खेल प्राधिकरण हेतु 03 वर्ष के समयावधि के लिए
                      ट्रांसपोर्टेशन एजेंसी का मनोनयन।
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="img/tenders/Corrigendum_E-Sports.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      ई स्पोर्ट्स के प्रोत्साहन के लिए परियोजना एवं निगरानी इकाई
                      की स्थापना
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="img/tenders/weightliftingcenterofexcellence.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      सेंटर ऑफ एक्सीलेंस के लिए खेल उपकरण क्रय करने के संबंध में
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="img/tenders/ESTABLISHMENTOFPROJECTMONITORING.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      ई-स्पोर्ट्स को बढ़ावा देने हेतु परियोजना निगरानी इकाई की
                      स्थापना- 3 वर्षों के लिए
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  {/* <li>
                    <a
                      href="https://forms.gle/ypDuzY6ixEEQ9uZx9"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      मल्लयुद्ध प्रतियोगिता के लिए आवेदन करें
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}

                  <li>
                    <a
                      href="img/notices/Final_List_02_11_2023.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      मेडल लाओ नौकरी पाओ की फाइनल मेरिट सूची
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  {/* <li>
                    <a
                      href="img/notices/Sports-Schedule-National Games 2023-Goa.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      37 वां नेशनल गेम्स 2023- खेल आयोजनों की तिथि
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}
                  {/*
                  <li>
                    <a
                      href="img/notices/Aupbandhik-Niyamavali-notice.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      औपबंधिक मेधासूची से संबंधित दावा/आपत्ति का निराकरण
                      प्रतिवेदन
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}

                  {/* <li>
                    <a
                      href="https://online.bih.nic.in/GADREC/(S(ncetcr12rl4ezt2gkg2dab3r))/Default.aspx"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      सीधी नियुक्ति नियमावली के अन्तर्गत चयनित खिलाड़ियों के
                      संदर्भ मे दावा/आपत्ति
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}

                  {/* <li>
                    <a
                      href="img/notices/SGFI-SEPAK-TAKRAW-NOTICE.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      नेशनल स्कूल गेम्स 2023 (सेपक टाकरा) बिहार
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}

                  {/* <li>
                    <a
                      href="img/notices/SGFI-FOOTBALL-NOTICE.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      नेशनल स्कूल गेम्स 2023 (फुटबॉल) बिहार
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}

                  {/* <li>
                    <a
                      href="img/notices/SGFI-CRICKET-NOTICE.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      नेशनल स्कूल गेम्स 2023 ( क्रिकेट) बिहार
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}

                  {/* <li>
                    <a
                      href="img/notices/SGFI-ATHLETICS-NOTICE.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      नेशनल स्कूल गेम्स 2023 ( एथेलेटिक्स) बिहार
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}

                  {/* <li>
                    <a
                      href="img/notices/SGFI-WEIGHTLIFTING-NOTICE.pdf"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      नेशनल स्कूल गेम्स 2023 (भारत्तोलन) बिहार
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li> */}

                  {/* <li>
                    <a
                      href="img/notices/vollyballteam_selection_notice_bihar.pdf"
                      target="_blank"
                    >
                      30 सितंबर और 01 अक्टूबर को हो रहा है बिहार के वॉलीबॉल
                      खिलाड़ियों का चयन।
                    </a>
                  </li> */}

                  <li>
                    <a href="img/notices/EOI.PDF" target="_blank">
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      बुनियादी स्तर पर प्रतिभा चयन के लिए जांच करने वाली
                      संस्थाओं का चयन।
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="img/notices/BSSA-Notice-Architect-11.09.23.PDF"
                      target="_blank"
                    >
                      <span>
                        <img
                          src="img/new-blink-image.gif"
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      </span>
                      बहुद्देशीय खेल परिसर और हॉस्टल के निर्माण के डिजाइन के लिए
                      आर्किटेक्ट की नियुक्ति।
                      <span style={{ margin: "0px 12px" }}></span>
                    </a>
                  </li>

                  {/* <li>
                    <a href="/" target="_blank">
                      बिहार में 33 खेलो इंडिया केंद्रों के लिए प्रशिक्षकों की
                      भर्ती
                    </a>
                  </li> */}

                  <li>
                    <a href="/" target="_blank">
                      {" "}
                      राष्ट्रीय खेल संस्थान, पटियाला में प्रवेश के लिए पंजीकरण{" "}
                    </a>
                  </li>
                  <li>
                    <a href="/" target="_blank">
                      {" "}
                      21 अगस्त से बिहार स्कूल ऑफ चेस के लिए नामांकन जारी।{" "}
                    </a>
                  </li>

                  {/* <!-- <li>
                  <a href="Download/OfficeOrderNo19147.pdf" target="_blank">Office Order No. 19147, Dtd.
                    26.10.2022</a>
                </li>
                <li>
                  <a href="https://dsy.samsodisha.gov.in/sports.aspx" target="_blank"> SAMS Online Application
                    for B.P.Ed. & M.P.Ed. course for the session 2022-24</a>
                </li>
                <li>
                  <a href="Download/RFP-CoachingServices-02102022.pdf" target="_blank"> RFP for professional
                    manpower and Coaching services for Athletics for Sports and Youth Services Department,
                    Odisha</a>
                </li>
                <li>
                  <a href="Download/IDcardsWeightageCertificates.pdf" target="_blank">ID cards & Weightage
                    Certificates Issued details</a>
                </li>
                <li>
                  <a href="Download/JuniorCoachFootball.pdf" target="_blank">Office Order - Parasar Bag, Junior
                    Coach (Football) kept in charge of DSO, Bolangir.Dtd: 30.03.2022</a>
                </li>
                <li>
                  <a href="Download/BijuPatnaikAward2021.pdf" target="_blank">Advertisement on Biju Patnaik
                    Sports Award- 2021</a>
                </li>
                <li>
                  <a href="Download/Bijupatnaikbraveryaward2021.pdf" target="_blank">Advertisement for Biju
                    Patnaik Bravery Award for the year 2021</a>
                </li>
                <li>
                  <a href="Download/ExtendedAuctionNotification.pdf" target="_blank">Extended Auction
                    Notification No. 1148</a>
                </li> -->
                <!--
                <li>
                     <a href="Download/Auctionnotificationsportsdepartment.pdf">Auction Notification</a>
                  </li>
                 <li>
                     <a href="Download/GeneralActivities.PDF">General Activities of the Sports & Youth Services Department  2020-21</a>
                  </li>
                  <li>
                     <a href="Download/Rendering-FMS-Corrigendum-2.pdf">Corrigendum-2: NIT for Rendering FMS</a>
                  </li>
                  <li>
                     <a href="Download/Corrigendum_1_FMS_Services.pdf">Corrigendum-1: NIT for Rendering FMS</a>
                  </li>
                  <li>
                     <a href="Download/Corrigendum-1-NIT-for-Rendering-Security-Services-5th-July-21.pdf">Corrigendum-1: NIT for Rendering of the Security Services</a>
                  </li>
                  <li>
                     <a href="Download/OfficerOrderforincharge-sports-baripada.pdf">Officer Order for Jr.Coach, Football & in-charge, Sports Hostel,Baripada</a>
                  </li>
                  <li>
                     <a href="Download/OfficerOrderforAnnualMaintenance.PDF">Officer Order inviting quotations from interested I.T firms/ Service Agencies for Annual Maintenance</a>
                  </li>
                  <li>
                     <a href="Download/NominationofBijuPttnaikBraveryAward.pdf">Nomination of Biju Pattnaik Bravery Award for the year, 2020</a>
                  </li>
                  <li>
                     <a href="Download/OfficerOrderSundargarhDistrict.pdf">Officer Order for befter administration and monitoring of Sports activities & infrastruture in Sundargarh District</a>
                  </li>
                  <li>
                     <a href="Download/promotion-principal.pdf">Promotion to principal of Govt College of Physical Education</a>
                  </li>
                  <li>
                     <a href="Download/PromotionOrderofSrCoach.pdf">Promotion to post of Sr. Coach</a>
                  </li>
                  <li>
                     <a href="Download/e-admission.pdf">Publication of Advertisement for admission in to B.P.Ed/M.P.Ed Courses in Govt, College of Physical Education Bhubaneswar & Kulundi, Sambalpur</a>
                  </li>
                  <li>
                     <a href="Download/AdvertisementcallingapplicationsforsettingupKheloIndiaDistrictCentrewithguidelines.pdf">Applications For Khelo India District Center</a>
                  </li>
                  <li>
                     <a href="Download/Applicationform.docx">Applications For Khelo India District Centre- Applications Form</a>
                  </li>
                  <li>
                     <a href="Download/SOPforResumptionSportsActivitescompressed.pdf">SOP for Resumption Sports Activites</a>
                  </li>
                  <li>
                     <a href="Download/tentage-work-catering.pdf">Tentage Work/ Catering for State Level Talent Scouting programme- 2019</a>
                  </li>
                  <li>
                     <a href="Download/tender-hiring-vehicles.pdf">Sealed Tender for providing diesel driven Non Ac/AC vehicles including driver for official use of the State Youth Welfare Board, Odisha</a>
                  </li>
--> */}
                </ul>
              </marquee>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
